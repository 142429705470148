<template>
  <div class="wrap">
    <iframe
      :src="
        isDev
          ? 'http://127.0.0.1:8000/#/?token=' + token
          : verConfig.chatUrl + '/#/?token=' + token
      "
      frameborder="0"
      class="third"
      ref="child"
    ></iframe>
    <!-- <div class="tab_wrap">
      <Tabs
        color="var(--tab_active_color)"
        background="var(--theme)"
        title-active-color="var(--tab_active_color)"
        title-inactive-color="#fff"
        v-model="currentActive"
      >
        <Tab title="thông tin"> </Tab>
        <Tab title="sổ địa chỉ"> </Tab>
      </Tabs>
      <div class="addFrinends" @click.stop="$router.push('/addFriends')">
        <i class="iconfont icon-tianjiahaoyou icon_add_friend"></i>
      </div>
    </div>
    <ChatList :numData="chatNumData" v-if="currentActive === 0" />
    <AddressBook
      :addFriendsNum="addFriendsNum"
      v-else-if="currentActive === 1"
    /> -->
  </div>
</template>

<script>
import { Tabs, Tab } from 'vant'
import ChatList from './chatlist.vue'
import AddressBook from './addressBook.vue'
import TimeCheck from '@/utils/timeSynchronization'
import { $get, $post } from '@/utils/request'
import { TOKEN_NAME } from '@/store'
// const timer = new TimeCheck({ time: new Date() });
export default {
  components: { Tabs, Tab, ChatList, AddressBook },
  data () {
    this.isDev = process.env.NODE_ENV === 'development'
    return {
      getMsgLoading: false,
      chatNumData: {},
      addFriendsNum: 0,
      currentActive: 0,
      token: ''
    }
  },
  created () {
    // timer.start();
    // timer.sleep(this.getMsgs, 3);
    this.token = localStorage.getItem(TOKEN_NAME) || ''
    if (!this.token) {
      this.$router.replace('/login')
    }
  },
  mounted () {
    window.addEventListener('message', (e) => {
      const { cmd, params } = e?.data
      if (cmd === 'UMsg') {
        const { num } = params
        this.$store.commit('SET_UNREAD_MESSAGE', num)
      }
    })
  },
  methods: {
    async getMsgs () {
      if (this.getMsgLoading) return
      this.getMsgLoading = true
      try {
        const {
          data: { ret, msg, data }
        } = await $get('/rooms/new/message')
        this.getMsgLoading = false
        this.chatNumData = Array.isArray(data.chat) ? {} : data.chat
        this.addFriendsNum = data.friend
      } catch (error) {
        this.getMsgLoading = false
      }
    }
  },
  beforeDestroy () {
    // timer.clear();
  }
}
</script>

<style lang="less" scoped>
@import url('./assets/iconfonts/iconfont.css');
.third {
  height: calc(100vh - 90px);
  width: 100vw;
  border: none;
}
.wrap {
  --tab_active_color: #ffff00;
  min-height: 100vh;
  background: #fff;
}
.tab_wrap {
  background: var(--theme);
  display: flex;
  .van-tabs {
    flex-grow: 1;
  }
  .van-tabs__nav {
    padding-top: 60px !important;
    // background: transparent;
  }
  .addFrinends {
    margin-right: 40px;
    height: 88px;
    // line-height: 88px;
    display: flex;
    align-items: center;
    > img {
      width: 20px * 2;
      height: 20px * 2;
    }
    .icon_add_friend {
      color: #fff;
      font-size: 46px;
    }
  }
}
</style>
