<template>
  <div class="userItem_wrap" @click="$emit('click')">
    <Badge v-if="!!badgeNum" :content="badgeNum">
      <img
        :src="$img(!!img ? img : defaultImg, !img ? true : !!isLocalImg)"
        alt=""
        class="avatar"
      />
    </Badge>
    <img
      v-else
      :src="$img(img || defaultImg, !img ? true : !!isLocalImg)"
      alt=""
      class="avatar"
    />
    <div class="nick_name">{{ text }}</div>
  </div>
</template>

<script>
import { Col, Row, Badge } from "vant";
const defaultImg = require("@/assets/icon/photo.png");
export default {
  components: { Col, Row, Badge },
  props: {
    img: {
      require: true,
      type: String,
      default: defaultImg,
    },
    text: {
      require: true,
      type: String,
      default: "",
    },
    badgeNum: {
      type: Number | String,
      default: 0,
    },
    isLocalImg: {
      type: Boolean,
    },
  },
  data() {
    return {
      defaultImg,
    };
  },
};
</script>

<style lang="less" scoped>
.ellipsis(@line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
}
.userItem_wrap {
  display: flex;
  @imgSize: 82px;
  .avatar {
    width: @imgSize;
    height: @imgSize;
    border-radius: 8px;
  }
  .nick_name {
    .ellipsis();
    flex-grow: 1;
    border-bottom: 1px solid #eee;
    font-size: 36px;
    align-items: center;
    margin-left: 18px;
    text-indent: 12px;
    white-space: nowrap;
    display: flex;
  }
}
</style>