<template>
  <!-- <div class="chat_list_wrap"> -->
  <!-- <NavBar
      title="Thông điệp"
      left-arrow
      @click-left="$router.go(-1)"
    /> -->
  <PullRefresh
    v-model="refreshing"
    @refresh="onRefresh"
    pulling-text="Kéo xuống để làm mới..."
    loosing-text="Phát hành để làm mới..."
    loading-text="Đang tải..."
    class="chat_list_wrap"
  >
    <div class="null" v-if="!chatList.length">không còn nữa</div>
    <List
      class="list"
      v-model="loading"
      :finished="!hasMore"
      :finished-text="''"
      loading-text="Đang tải"
      @onload="getList"
      v-else
    >
      <div
        class="list_item"
        v-for="(item, index) in chatList"
        :key="item.id || `list_item_${index}`"
        @click="toRoom(item)"
      >
        <img :src="item.userimg || defaultImg" alt="" class="avatar" />
        <div class="right_bar">
          <div class="top_bar">
            <div class="title">{{ item.username }}</div>
            <Badge :content="badgeGet(item)" v-if="!!badgeGet(item)" />
          </div>
          <div class="content_bar">
            <div class="content">
              <div
                v-html="
                  item.comment_new[0]
                    ? item.comment_new[0].type == 'image'
                      ? `[hình ảnh]`
                      : item.comment_new[0].content
                    : ''
                "
              ></div>
              <!-- {{
                  item.comment_new[0]
                    ? item.comment_new[0].type == "image"
                      ? `[${$t("chat.list.image")}]`
                      : item.comment_new[0].content
                    : ""
                }} -->
            </div>
            <div class="time">
              {{
                item.comment_new[0]
                  ? date(item.comment_new[0].indate * 1000)
                  : date(item.update * 1000)
              }}
            </div>
          </div>
        </div>
      </div>
    </List>
  </PullRefresh>
  <!-- </div> -->
</template>

<script>
import { NavBar, List, Badge, PullRefresh } from 'vant'
import { $get } from '@/utils/request.js'
const defaultImg = require('@/assets/icon/photo.png')
export default {
  components: { NavBar, List, Badge, PullRefresh },
  props: {
    numData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      chatList: [],
      hasMore: true,
      loading: false,
      newsSeen: {},
      currentTab: 0,
      defaultImg,
      refreshing: false
    }
  },
  created () {
    this.getList()
    const data = localStorage.getItem('seen')
    !!data && (this.newsSeen = JSON.parse(data))
  },
  methods: {
    badgeGet (item) {
      // const index = this.newsSeen[item.id];
      const num = this.numData[item.id] ?? 0
      // if (!!index) {
      //   num = item.comment_new[0].index - index;
      // } else {
      //   num = item.comment;
      // }
      if (num > 99) {
        return '99+'
      }
      return num
    },
    onRefresh () {
      this.getList()
    },
    addLoading () {
      this.loading = true
    },
    clearLoading () {
      this.loading = false
      this.refreshing = false
    },
    async getList () {
      this.loading = true
      const listOfLocal = localStorage.getItem('chatListData')
      if (listOfLocal) {
        this.chatList = JSON.parse(listOfLocal)
      }
      try {
        const {
          data: { ret, msg, data }
        } = await $get('/rooms/lists', { pagesize: 1000 })
        this.loading = false
        this.refreshing = false
        if (!data) return (this.hasMore = false)
        if (ret == 1) {
          const { hasMorePages, lists } = data
          this.chatList = lists
          localStorage.setItem('chatListData', JSON.stringify(lists))
          this.hasMore = hasMorePages
        }
      } catch (error) {
        console.log(error)
        this.loading = false
        this.refreshing = false
      }
    },
    date (time) {
      if (!time) return ''
      const _time = this.$format(time, 'yy-MM-dd')
      const now = this.$format(new Date(), 'yy-MM-dd')
      if (_time == now) {
        const diff = new Date().getTime() - new Date(time).getTime()
        const _s = 1000
        const _m = 60 * _s
        const _h = 60 * _m
        const _hour = Math.floor(diff / _h)
        const _minute = Math.floor((diff % _h) / _m)
        let str = ''
        if (_hour) {
          str = `${_hour} giờ`
        }
        if (_minute) {
          str += `${_minute} phút`
        }
        return `${str} trước`
      }
      return this.$format(time)
    },
    toRoom (item) {
      this.$router.push(`/ChatRoom?id=${item.id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.ellipsis(@line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
}
.tab_wrap {
}
.chat_list_wrap {
  min-height: 80vh;
}
.null {
  text-align: center;
  padding: 20px 0;
  color: #999;
  font-size: 32px;
}
.list {
  margin: 24px 16px 16px;
  .list_item {
    display: flex;
    height: 128px;
    margin-top: 26px;
    &:nth-child(1) {
      margin-top: 0;
    }
    .avatar {
      width: 110px;
      height: 110px;
      border-radius: 4px;
    }
    .right_bar {
      margin-left: 42px;
      flex-grow: 1;
      .top_bar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;
        .title {
          width: 88%;
          .ellipsis();
          font-size: 34px;
          // line-height: 50px;
        }
      }
      .content_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        min-height: 20px;
        border-bottom: 1px solid #dedede;
        .content {
          font-size: 34px;
          color: #999;
          .ellipsis();
        }
        .time {
          font-size: 20px;
          color: #b7b7b7;
          margin-left: 20px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
